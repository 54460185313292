

/* agriflow/assets/scss/main.scss */
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';

:root{
    --bs-btn-color: #fff;
    --bs-btn-bg: #79BF4A;
    --bs-btn-border-color: #79BF4A;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
    --bs-body-font-size: .9rem;
}

body{
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
}

.wrapper-main{
    height: 100%;
    min-height: 100vh;
    background-color: #f2f2f2;
    border: .56rem solid #fff;
    border-radius: 1rem;
    &.active {
        margin-left: 250px;
    }
}


.wrapper-navbar,
.wrapper-main,
.wrapper-sidebar {
    transition: all 0.3s ease;
}

.wrapper-sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    height: calc(100% - 0.6rem);
    width: 250px;
    background-color: #fff;
    transition: all 0.3s ease;
    z-index: 100;
    padding: 0.5rem;
    margin: 0.3rem;
    border-radius: .3rem;

    &.active {
        left: 0;
    }

    &.pinned {
        height: 100%;
        margin: 0;
    }
    .wrapper-body{
        height: 100%;
        padding: .5rem;
        border-radius: .3rem;
        background-color: #f2f2f2;
    }

    .menu-item{
        display: block;
        padding: .15rem 0;
        a{
            text-decoration: none;
        }
        .menu-link{
            transition: color .2s ease;
            color: #1c1c21;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0;
            flex: 0 0 100%;
            padding: .65rem 1rem;
            transition: none;
            outline: 0 !important;
            border-radius: .475rem;
            &.active{
                background-color: #f5f5f5;
            }
            i{
                font-size: .8rem;
            }
            .badge{
                .fa-layer-group{
                    font-size: .6rem;
                }
            }
        }
        .hover-bg {
            transition: all 0.2s ease;
            &:hover {
                background-color: var(--bs-light-bg-subtle) !important;
                color: var(--bs-secondary-color) !important;
            }
        }
        .menu-title{
            display: flex;
            align-items: center;
            flex-grow: 1;
            font-size: .8rem !important;
            font-weight: 500 !important;
            text-decoration: none;
        }
    }
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 9;
    
    &.active {
        opacity: 1;
        visibility: visible;
    }
}




// Tabel
#activitiesListUser > thead > tr > th{
    font-weight: 500;
    font-size: .8rem !important;
}
#table-head > th{
    font-weight: 500;
    font-size: .8rem !important;
}
#activitiesListUser > tbody > tr > td,
#data-table > tbody > tr > td{
    color: #6c757d !important;
    font-weight: 400;
    font-size: .9rem !important;
}
.table>:not(:first-child) {
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
}
td.details-control {
    background: url('https://www.datatables.net/examples/resources/details_open.png') no-repeat center center;
    cursor: pointer;
}
tr.shown td.details-control {
    background: url('https://www.datatables.net/examples/resources/details_close.png') no-repeat center center;
}
#sortable-table th {
    font-size: .9rem;
}
#sortable-table td {
    font-size: .9rem;
}
#pricelistTextModalForm th,
#pricelistTextModalForm tr,
#pricelistTextModalForm td,
#pricelistMachinerysModalForm tr,
#pricelistObjectModalForm tr,
#pricelistServiceModalForm tr,
#pricelistMachinerysModalForm th,
#pricelistObjectModalForm th,
#pricelistServiceModalForm th {
    font-size: .8rem;
}
#pricelistMachinerysModalForm td,
#pricelistObjectModalForm td
#pricelistServiceModalForm td {
    font-size: .8rem;
}



.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear,
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear {
    border: 0;
}

.breadcrumb-nav{
    height: auto;
}

//
.dropdown-menu.show{
    width: 100%;
}


//
.service-field, 
.object-field {
    display: none;
}

#id_payment_status .form-label,
#div_id_customer .form-label {
    display: block;
    margin-bottom: 0;
}


.cursor-grab {
    cursor: grab;
}

#dataContainerOffer > #sortable-table > tbody > tr.bg-warning-table{
    --bs-table-bg: #fff3cd !important;
}

#dataContainerOffer > #sortable-table > tbody > tr.bg-info-table{
    --bs-table-bg: #cff4fc !important;
}

.btn-xs {
    padding: 0.15rem 0.3rem;
    font-size: 0.65rem;
    line-height: 1.2;
    border-radius: 0.15rem;
}


.form-label-sm {
    font-size: 0.75rem;
    margin-bottom: 0;
}



// Sidebar themplates
.sidebar-open {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    @include media-breakpoint-down(md) {
        padding: 10px 20px;
    }
}

.sidebar-themplates-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; 
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease; 
    
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.sidebar-wrapper{
    width: 50%;
    height: 100%;
    background-color: #fff;
    z-index: 99999; 
    position: fixed;
    right: -100%; 
    top: 0;
    transition: all .5s ease; 
    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.sidebar-body{
    position: relative;
}

.sidebar-close{
    cursor: pointer;
}

.sidebar-close .fa-solid{
    font-size: 1.2rem;
}

body.overlay-active {
    overflow: hidden;
}

.sidebar-themplates-overlay.active .sidebar-wrapper {
    right: 0;
}


//Select2
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-radius: 4px !important;
    font-size: 0.875rem !important;
    border-radius: var(--bs-border-radius-sm) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

// fonts
.fs-7{
    font-size: 0.875em !important;
}


// 

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.disabled i {
    opacity: 0.5;
}

.disabled i {
    opacity: 0.5;
    color: #999;
}





/// Preview
.company-logo{
    width: 220px;
    height: auto;
    margin-bottom: 1rem;
}

#signatureCanvas{
    border: 1px solid rgb(222, 226, 230); touch-action: none;
    border-radius: 10px;
    background-color: #f1f1f1;
}


.disabled{
    opacity: 1 !important;
}

.btn:disabled, 
.btn.disabled{
    opacity: .5 !important;
    cursor: not-allowed !important;
}



// dashboard
#dashboard{
    margin-top: -15px;
    @media (max-width: 1000px) {
        margin-top: -30px;
    }
    .icon-invoice{
        width: 38px;
        height: 38px;
        border-radius: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1000px) {
            width: 28px;
            height: 28px;
        }
    }

    .card-body {
        flex: 1 1 auto;
        padding: .5rem .5rem;
        color: var(--#{$prefix}card-color);
    }

    .card-title {
        font-size: 1rem;
        margin: 0;
        @media (max-width: 1000px) {
            font-size: .7rem;
        }
    }
}

// 

.withdrawal-card {
    height: calc(100vh - 100px);
    overflow-y: auto;
}