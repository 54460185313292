

.contact-card{
    position: relative;
    .edit-icon{
        top:0;
        right: 0;
        position: absolute;
        padding: 1rem;
        i{
            background-color: #b6d4fe;
            border-radius: 5px;
            font-size: 1.2rem;
            padding: .2rem;
            cursor: pointer;
        }
        i:hover{
            background-color: #e3e4e4;
        }
    }
    .delete-modal{
        top:2rem;
        right: 0;
        position: absolute;
        padding: 1rem;
        i{
            background-color: #f5c2c7;
            border-radius: 5px;
            font-size: 1.1rem;
            padding: .3rem;
            cursor: pointer;
        }
        i:hover{
            background-color: #e3e4e4;
        }
    }
}